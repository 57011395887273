<template>
  <div class="ui-branding">
    <!-- Title -->
    <div
      class="ui-branding__title"
      v-if="hasTitle || hasTitleSlot"
    >
      <h1
        v-if="hasTitle && !hasTitleSlot"
        v-html="title"
      ></h1>

      <slot
        name="title"
        v-if="hasTitleSlot"
      ></slot>
    </div>

    <!-- Description -->
    <div
      class="ui-branding__description"
      v-if="hasDescription || hasDescriptionSlot"
    >
      <p
        v-if="hasDescription && !hasDescriptionSlot"
        v-html="description"
      ></p>

      <slot
        class="ui-branding__description"
        name="title"
        v-if="hasDescriptionSlot"
      ></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiBranding',

  props: {
    description: {
      type: String,
      default: null,
    },

    title:{
      type: String,
      default: null,
    }
  },

  computed: {
    hasDescription() {
      return !this.$basil.isNil(this.description) && !this.$basil.isEmpty(this.description)
    },

    hasDescriptionSlot() {
      return !!this.$basil.get(this, '$slots.description')
    },

    hasTitle() {
      return !this.$basil.isNil(this.title) && !this.$basil.isEmpty(this.title)
    },

    hasTitleSlot() {
      return !!this.$basil.get(this, '$slots.title')
    }
  }
}
</script>
