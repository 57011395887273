// non-mandatory steps will be displayed on the optionnal step page
// flag for non-mandatory steps allow to display the validity check

export default [
  {
    name: 'info',
    route: 'sayl-connect_onboarding-info',
    isMandatory: true,
    order: 0,
    isImportant: true,
    disabled: false
  },

  {
    name: 'optionals',
    route: 'sayl-connect_onboarding-optional-steps',
    isMandatory: true,
    order: 1,
    isImportant: true,
    disabled: true
  },

  {
    name: 'terms',
    route: 'sayl-connect_onboarding-terms',
    isMandatory: true,
    order: 2,
    isImportant: true,
    flag: 'tcOptin',
    disabled: false
  },

  {
    name: 'pin',
    route: 'sayl-connect_onboarding-pin',
    isMandatory: false,
    order: 1,
    isImportant: true,
    flag: 'pinSet',
    disabled: false
  },

  {
    name: 'email',
    identifier: 'email',
    route: 'sayl-connect_onboarding-resend',
    isMandatory: false,
    order: 0,
    isImportant: false,
    flag: 'emailVerified',
    disabled: false
  },

  {
    name: 'phone',
    identifier: 'phone_number',
    route: 'sayl-connect_onboarding-confirm-phone',
    isMandatory: false,
    order: 0,
    isImportant: true,
    flag: 'phoneVerified',
    disabled: false
  }
]
