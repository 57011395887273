<template>
  <div class="layout-onboarding">
    <div class="layout-onboarding__route">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutOnboarding',

  methods: {
    resize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  },

  mounted() {
    window.addEventListener('resize', this.resize)
    this.resize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }
}
</script>
