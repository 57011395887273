<template>
  <layout-onboarding>
    <div :key="$route.name" class="onboarding module-onboarding" :class="classes">
      <header class="onboarding__header">
        <img
        alt="Logo Sayl Connect"
        class="onboarding__logo"
        :src="logo"
        />

        <locale-select class="onboarding__locale" />
      </header>

      <div class="onboarding__body">
        <landing-branding
          v-if="isInStep"
          class="onboarding__branding"
          :description="description"
          :title="title"
        />

        <div class="onboarding__step">
          <router-view @next="onNextStep" />
        </div>
      </div>
    </div>
  </layout-onboarding>
</template>

<script>
import { mapState } from 'vuex'
import LayoutOnboarding from '@/layouts/onboarding.vue'
import LandingBranding from '@/components/ui/branding.vue'
import Header from '@/components/navigation/header.vue'
import LocaleSelect from '@/components/actions/locale-select.vue'

import onboardingSteps from '@/data/onboarding'

export default {
  name: 'Onboarding',

  components: {
    LayoutOnboarding,
    LandingBranding,
    Header,
    LocaleSelect
  },

  data() {
    return {
      currentStep: 0,
    }
  },

  computed: {
    ...mapState({
      bootstrap: state=> state.bootstrap.data
    }),

    classes() {
      let classes = {}

      if(this.$route.meta?.class) {
        classes[this.$route.meta.class] = this.$route.meta.class
      }

      return classes
    },

    steps() {
      if(!onboardingSteps?.length) return []

      return onboardingSteps.filter(s => (s.isMandatory && !s.disabled)).sort((a, b) => a.order - b.order)
    },

    description() {
      if(this.$route.name === 'sayl-connect_onboarding-confirm-phone') {
        return this.$t(`conn3ct-wallet.login_step_validate_phone_description`)
      }

      return this.$t(`conn3ct-wallet.login_step_${ this.steps[this.currentStep]?.name }_description`, { email: this.$basil.get(this.$route, 'params.email', '') })
    },

    title() {
      if(this.$route.name === 'sayl-connect_onboarding-confirm-phone') {
        return this.$t(`conn3ct-wallet.login_step_validate_phone_title`)
      }

      return this.$t(`conn3ct-wallet.login_step_${ this.steps[this.currentStep]?.name }_title`)
    },

    isInStep() {
      return !!this.steps[this.currentStep]
    },

    merchantLogo() {
      return this.bootstrap?.brand?.logo
    },

    logo() {
      if(this.merchantLogo) {
        return this.translate(this.merchantLogo.url)
      }

      return this.$root.getSrc('statics/images/sayl-logo-color.svg')
    }
  },

  methods: {
    checkNextStep() {
      this.currentStep++

      let step = this.steps[this.currentStep]

      if(this.$basil.isNil(step)) {
        this.closeOnboarding()
        return
      }

      const { flag } = step

      if(!this.$basil.get(this.$user, `user[${flag}]`, false)) {
        this.$router.replace({ name: step.route }).catch(() => {})
        return
      }

      this.checkNextStep()
    },

    async closeOnboarding() {
      try {
        await this.$user.setOnboardingStatus()
        this.$router.replace({ name: 'sayl-connect_dashboard-dashboard' }).catch(() => {})
      } catch(e) {
        throw e
      }
    },

    async init() {
      try {
        await this.$user.getUser()

        const informationsFilled = (
          !this.$basil.isNil(this.$user.user.firstname) && !this.$basil.isEmpty(this.$user.user.firstname) &&
          !this.$basil.isNil(this.$user.user.lastname) && !this.$basil.isEmpty(this.$user.user.lastname)
        )

        if(!informationsFilled) {
          this.$router.replace({ name: this.steps[0].route }).catch(() => {})
        } else {
          this.checkNextStep()
        }
      } catch(e) {

      }
    },

    onNextStep(isOptional = false) {
      if(isOptional) {
        this.$router.replace({ name: 'sayl-connect_onboarding-optional-steps' }).catch(() => {})
        return
      }

      this.checkNextStep()
    },
  },

  created() {
    this.init()
  },

}
</script>
